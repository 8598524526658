




























































import { Vue, Component, Mixins } from 'vue-property-decorator'

@Component
export default class extends Mixins() {
  t1: any

  mounted() {
    this.t1 = setInterval(this.timeControl, 2300)

    $('.message_scroll_box')
      .on('mouseenter', () => {
        clearInterval(this.t1)
      })
      .on('mouseleave', () => {
        this.t1 = setInterval(this.timeControl, 2500)
      })
  }

  timeControl() {
    $('.message_scroll_box').animate({ marginTop: 96 }, 800, () => {
      $('.message_scroll_box').css({ marginTop: 0 })
      $('.message_scroll_box .message_scroll:first').before(
        $('.message_scroll_box .message_scroll:last')
      )
    })
  }

  beforeDestroy() {
    clearInterval(this.t1)
  }
}
